.text_style[data-v-54adf146] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ml-mr-0[data-v-54adf146] {
  margin: 0;
}
.w145[data-v-54adf146] {
  width: 145px !important;
}